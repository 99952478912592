.Relation_main {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
        font-size: 3vmin
    }

    .daoh {
        width: 100%;
        height: 3.5rem;
        border-bottom: 1px solid #ccc;
        display: flex;
        flex-direction: column;
        align-items: center;

        .daoh_content {
            width: 80%;
            height: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .gsjs {
                font-weight: bold;
            }
            .mbx {
                display: flex;
                color: #928989;
                align-items: center;
                img{

                    width: 1.2rem;
                    height: 1.2rem;
                    margin: 0 0.7rem;
                    // position: relative;
                    // top: 0.2rem;
                    }
            }
        }
    }

    .Relation_big {
        width: 100%;
        background: url('../../assets/imgs/relation/relation_backgrout1.png');
        display: flex;
        justify-content: center;

        .biaodan_main {
            // border: 1px solid red;
            width: 80%;
            margin: 3vmin 0;
            background-color: #fff;
            padding: 8vmin;
            

            .fg {
                height: 0.2vmin;
                margin: 1vmin 0;
                margin-bottom: 3vmin;
                background-color: #2a7bdd;
            }

            .biaodang_conten {
                display: flex;
                justify-content: space-between;
                .left,.right{
                    width: 34vw;
                    *{
                        font-size: inherit;
                        h1{
                            font-size: 16px;
                        }
                    }
                }
            }
            .relation_btn{
                background-color: #fff;
                border: 0;
                color: #2a7bdd;
            }
            .ant-col-offset-8{
                margin-inline-start: 0;
            }
            .ant-btn-primary,.ant-btn-default{
                display: flex !important;
                justify-content: center !important ;
                align-items: center !important;
            }
        }
    }
}