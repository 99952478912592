.news_main {
  height: 100%;
  width: 100%;
  background-color: rgb(246, 249, 252);
  padding-bottom: 2vmin;
  // banner
  .w95 {
    width: 80%;
    margin: 0 auto;
  }

  // middle
  .middleBox {
    margin: 0 auto;
    overflow: hidden;
    border-bottom: 1px solid #ccced1;
    background-color: #f6f9f9;

    .middle {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    // left
    .left {
      position: relative;
      top: 1vmin;
    }

    // position
    .position {
      height: 2.5rem;
      // width: 50%;

      width: 18rem;
      font-size: 1rem;
      text-align: right;
      line-height: 2.5rem;
      img {
        width: 1.2rem;
        height: 1.2rem;
        margin: 0 0.7rem;
        position: relative;
        top: 0.2rem;
      }
      a {
        color: black;
      }
      span {
        color: rgb(107, 103, 103);
      }
    }
  }
}
