

//右入跌荡
.animationIn{
    visibility: visible;
    animation: lightSpeedIn 2s;
}

@keyframes lightSpeedIn{
    0%{
        transform: translate3d(100%, 0,0) skewX(-30deg);
        opacity: 0;
    }
    60%{
        transform: skewX(20deg);
        opacity: 1;
    }
    80%{
        transform: skewX(-5deg);
        opacity: 1;
    }
    100%{
        transform: none;
        opacity: 1;
    }
}
//div晃动
.animationInX{
    visibility: visible;
    animation: flipInX 2s;
}
@keyframes  flipInX {
  0% {
      -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
      transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
      -webkit-transition-timing-function: ease-in;
      transition-timing-function: ease-in;
      opacity: 0;
  }

  40% {
      -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
      transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
      -webkit-transition-timing-function: ease-in;
      transition-timing-function: ease-in;
  }

  60% {
      -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
      transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
      opacity: 1;
  }

  80% {
      -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
      transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }

  100% {
      -webkit-transform: perspective(400px);
      transform: perspective(400px);
  }
}
// 右推进
.animationright{
    visibility: visible;
    animation: fadright 2s;
}
@keyframes fadright {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
    }

    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}
//左推进
.animationleft{
    visibility: visible;
    animation: fadleft 2s;
}
@keyframes fadleft{
    0% {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
    }
    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}
//向上推
.animationup{
    visibility: visible;
    animation: fatup 2s;
}
@keyframes fatup{
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }
    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}
//小变大
.animationmin{
    visibility: visible;
    animation: fatmin 2s;
}
@keyframes fatmin{
    0% {
        opacity: 0;
        -webkit-transform: scale3d(.3, .3, .3);
        transform: scale3d(.3, .3, .3);
    }
    100% {
        opacity: 1;
    }
}
