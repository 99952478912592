.products {
    height: 100%;
    width: 100%;
    background-color: #fff;
    display: flex;
    align-items: center;
    flex-direction: column;

    .biaod {
        width: 80%;
        margin: 4vmin 0;
        padding: 3vmin;
        box-shadow: 1px 5px 21px #9c9797;
    }
}