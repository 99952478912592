.ClientCaseDetails_box {
  .ClientCaseDetails {
    // background: linear-gradient(
    //   45deg,
    //   #fff,
    //   #e4ecef,
    //   #fff,
    //   #e4ecef,
    //   #fff,
    //   #e4ecef,
    //   #fff,
    //   #e4ecef,
    //   #fff
    // );
    // width: 80%;
    margin: 0 auto;
    .casedetailBg {
      // width: 80%;
      margin: 0 auto;
      background-image: url("../../assets/imgs/client/clientdetails.webp");
      background-size: cover;
      //   .your-container::before {
      //     content: "";
      //     background-image: url("../../assets/imgs/client/clientdetails.jpg");
      //     opacity: 0.5; /* 设置背景图透明度 */
      //     position: absolute;
      //     top: 0;
      //     left: 0;
      //     right: 0;
      //     bottom: 0;
      //     z-index: -1; /* 确保位于内容之后 */
      // }
      .caseDetail {
        width: 80%;
        margin: 0 auto;
        padding-top: 1rem;
        .casecontent {
          width: 60%;
          margin: 0 auto;
          margin-top: 6vmin;
          h1 {
            text-align: center;
            margin: 1rem 0 2rem 0;
            font-size: 2rem;
          }
          p {
            text-indent: 2em;
            font-size: 1.1em;
            line-height: 1.8em;
            margin-bottom: 1.5em;
          }
        }
      }
    }
    .caseswiperBox {
      margin: 0 auto;
      width: 80%;
    }
  }
}
