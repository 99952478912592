.gallery_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  .main_image {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2); // 水平偏移、垂直偏移、模糊半径、阴影颜色
    height: 60vmin;
    width: 90vmin;
    overflow: hidden;
    background-color: rgb(234, 238, 240);
    padding: 0 10vmin;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .galleryImg {
      width: 90vmin;
      height: 60vmin;
      display: block;
      object-fit: contain;
      margin: 0;
    }
  }

  .thumbs {
    display: flex;
    width: 100%;
    overflow-x: hidden;
    margin: 6vmin auto;
    // justify-content: flex-start;
    padding: 4vmin 0;
    justify-content: space-between;
  }

  .thumbs img {
    width: 16vmin;
    height: 12vmin;
    object-fit: cover;
    margin-right: 10px;
    cursor: pointer;
    border: 2px solid transparent;
    transition: all 0.5s ease;
    opacity: 0.6;
  }

  .thumbs img.active {
    transform: scale(1.5);
    opacity: 1;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); // 水平偏移、垂直偏移、模糊半径、阴影颜色
  }

  .arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: #898181;
    color: #fff;
    border: none;
    border-radius: 50%;
    width: 4vmin;
    height: 4vmin;
    font-size: 1.7vmin;
    transition: all 0.5s ease;
    cursor: pointer;
  }
  .arrow:hover {
    font-size: 2.2vmin;
    background: #383535;
  }

  .prev {
    left: 2vmin;
  }

  .next {
    right: 2vmin;
  }
}
