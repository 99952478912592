.banner_body {
  height: 100%;
  width: 100%;
  img {
    height: 30em;
    width: 100%;
    object-fit: cover; /* 图片按比例缩放，保持宽高比并填充整个容器 */
  }
  .lunbo {
    height: 30em;
    width: 100%;
    background-size: 100%;
    position: absolute;
    .text {
      padding: 7em;
      padding-top: 12rem;
      padding-left: 15em;
      color: #fff;

      .text1 {
        font-size: 18px;
        color: #ccc;
      }

      h1 {
        margin: 1.7vh 0;
        font-size: 4vmin;
        text-shadow: 1px 1px 1px #6bb2e5;
      }

      .text2 {
        font-size: 20px;
        font-weight: bolder;
      }
    }
  }
}
