/* 电脑端样式 */
@media screen and (min-width: 1024px) {
    .products_index {
        height: 100%;
        width: 100%;

        .daohang {
            height: 3.5rem;
            line-height: 3.5rem;
            display: flex;
            justify-content: space-between;
            padding: 0 13em;
            align-items: center;
            border-bottom: 1px solid #ccc;

            .bt {
                // color: #2a7bdd;
                font-weight: bold;
            }

            .mbx {
                img {

                    width: 1.2rem;
                    height: 1.2rem;
                    margin: 0 0.7rem;
                    position: relative;
                    top: 0.2rem;
                }
            }
        }

        // 内容
        .products_main {
            width: 100%;
            height: 100%;
            // background: #e3e2e2;
            display: flex;
            justify-content: center;
            background: url('../../../../assets/imgs/product/bg1.jpg') no-repeat center;
            background-size: 100%;

            .content {
                width: 80%;
                margin-bottom: 4vmin;
                margin-top: 9vmin;
                position: relative;

                .mbx {
                    position: absolute;
                    top: 0;
                    right: 0;
                }

                ul {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-evenly;

                    .li {
                        display: flex;
                        justify-content: space-around;
                        flex-direction: column;
                        height: 21vh;
                        margin: 0 6vmin;
                        align-items: center;


                        .img {
                            width: 14vmin;
                            height: 14vmin;
                            border-radius: 50%;
                            border: 1px solid #2a7bdd;
                            display: block;
                            // animation: rotate 3s linear infinite;
                        }

                        @keyframes rotate {
                            0% {
                                transform: rotatey(0deg);
                                /*从0度开始*/
                            }

                            100% {
                                transform: rotatey(360deg);
                                /*360度结束*/
                            }
                        }

                        .img:hover {
                            animation: rotate 3s ease-in-out infinite;
                            background: #fff;
                        }
                    }
                }
            }
        }
    }
}

/* 手机端样式 */
@media screen and (max-width: 1023px) {
    .products_index {
        height: 100%;
        width: 100%;

        .daohang {
            height: 13vmin;
            width: 100%;
            display: flex;
            padding: 0 10vw;
            border-bottom: 1px solid #ccc;
            justify-content: space-between;
            align-items: center;

            .bt {
                // color: #2a7bdd;
                font-weight: bold;
            }

            .mbx {
                img {

                    width: 1.2rem;
                    height: 1.2rem;
                    margin: 0 0.7rem;
                    position: relative;
                    top: 0.2rem;
                }
            }
        }

        // 内容
        .products_main {
            width: 100%;
            height: 100%;
            // background: #e3e2e2;
            display: flex;
            justify-content: center;
            background: url('../../../../assets/imgs/product/bg1.jpg') no-repeat center;
            background-size: 100%;

            .content {
                width: 80%;
                margin-bottom: 4vmin;
                margin-top: 9vmin;
                position: relative;

                .mbx {
                    position: absolute;
                    top: 0;
                    right: 0;
                }

                ul {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-evenly;

                    .li {
                        display: flex;
                        justify-content: space-around;
                        flex-direction: column;
                        height: 21vh;
                        margin: 0 6vmin;
                        align-items: center;


                        .img {
                            width: 14vmin;
                            height: 14vmin;
                            border-radius: 50%;
                            border: 1px solid #2a7bdd;
                            display: block;
                            // animation: rotate 3s linear infinite;
                        }

                        @keyframes rotate {
                            0% {
                                transform: rotatey(0deg);
                                /*从0度开始*/
                            }

                            100% {
                                transform: rotatey(360deg);
                                /*360度结束*/
                            }
                        }

                        .img:hover {
                            animation: rotate 3s ease-in-out infinite;
                            background: #fff;
                        }
                    }
                }
            }
        }
    }
}