/* 电脑端样式 */
@media screen and (min-width: 1024px) {
    
    #form_mai {
        *{
            font-size: inherit;
            h1{
                font-size: 16px;
            }
        }
        .content {
            display: flex;
            justify-content: space-between;
            margin-top: 1em;
    
            .item {
                width: 50%;
                // border: 1px solid brown;
                box-sizing: border-box;
            }
    
            .left {
                padding-right: 2vmin;
                font-size: 14px;
                .left_item {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
    
                    .left_item1 {
                        width: 49%;
                        font-size: 14px;
                    }
                    .css-dev-only-do-not-override-1k979oh
                    .css-dev-only-do-not-override-1k979oh {
                        margin-inline-start: 0% !important;
                        max-width: 100% !important;
                        font-size: 14px;
                    }
    
                    .css-dev-only-do-not-override-1k979oh {
                        max-width: 100% !important;
                        font-size: 14px;
                    }
                    .ant-input{
                        max-width: 100% !important;
                        font-size: 14;
                    }
                    #basic {
                        max-width: 100%;
                    }
                }
                .ant-col-offset-8{
                    margin-inline-start: 0;
                    font-size: 14px;
                }
                .ant-btn-primary,.ant-btn-default{
                    display: flex !important;
                    justify-content: center !important ;
                    align-items: center !important;
                }
                .ant-col-16{
                    max-width:100%;
                    // font-size: 1vmin;
                }
                .ant-select-selection-item{
                    font-size: 14px;
                }
            }
    
            .right {
                height: calc(12vh + 300px);
                width: 50%;
                // background: url('../assets/imgs/first_imgs/bd_background.jpg') no-repeat center;
                background-size: 100%;
                // border: 1px solid red;
            }
        }
    }
}

/* 手机端样式 */
@media screen and (max-width: 1023px) {
   
    #form_mai {
        *{
            font-size: inherit;
            h1{
                font-size: 16px;
            }
        }
        .content {
            display: flex;
            margin-top: 1em;
            flex-direction: column;
    
            .item {
                width: 50%;
                // border: 1px solid brown;
                box-sizing: border-box;
            }
    
            .left {
                // padding-right: 2vmin;
                width: 100%;
    
                .left_item {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    font-size: 14px;
    
                    .left_item1 {
                        width: 49%;
                        // float: right;
                        font-size: 14px;
                    }
                    .left_item2 {
                        margin-right: 1rem;
                        
                    }
    
                    .css-dev-only-do-not-override-1k979oh .css-dev-only-do-not-override-1k979oh {
                        margin-inline-start: 0% !important;
                        max-width: 100% !important;
                        font-size: 14px;
                    }
    
                    .css-dev-only-do-not-override-1k979oh {
                        max-width: 100% !important;
                        font-size: 14px;
                    }
    
                    .ant-input {
                        max-width: 100% !important;
                        font-size: 14px;
                    }
    
                    #basic {
                        max-width: 100%;
                        font-size: 14px;
                    }
                }
    
                .ant-col-offset-8 {
                    margin-inline-start: 0;
                    font-size: 14px;
                }
    
                .ant-btn-primary,
                .ant-btn-default {
                    display: flex !important;
                    justify-content: center !important;
                    align-items: center !important;
                    font-size: 14px;
                }
    
                .ant-col-16 {
                    max-width: 100%;
                    font-size: 1vmin;
                    font-size: 14px;
                }
                .ant-select-selection-item{
                    font-size: 14px;
                }
            }
    
            .right {
                // display: none;
                height: 0vh;
                // width: 100%;
    
    
            }
        }
    }
}
