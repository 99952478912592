/* 手机端样式 */
@media screen and (max-width: 1023px) {
  .home {
    .header_big {
      height: 100%;
      padding: 1vmin;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      div {
        line-height: 3vmin;
      }

      .header_min {
        font-size: 1.3vmin;
        // color: #999;
      }
    }

    .ant-menu-dark .ant-menu-item-selected {
      color: #fff !important;
    }

    .header_logo {
      height: 8vmin;
      flex: 0.5 1;
      text-align: left;
      display: flex;
      align-items: center;
      justify-content: space-around;

      .header_logoimg {
        // max-width: 100%;
        // max-height: 100%;
        min-width: 55%;
      }

      // .ant-menu-dark.ant-menu-horizontal >.ant-menu-item-selected{
      //     color: #555;
      // }
    }

    .header .ant-menu-item {
      color: #555;
      font-size: larger;
    }
    // 修改省略号颜色
    .ant-menu-submenu-title {
      color: #413b3b;
    }
    .header {
      .header_lxwm {
        flex: 0.5 1;
        display: flex;
        justify-content: center;
        font-size: larger;
        align-items: center;
      }

      .header_lxwm span {
        height: 5vmin;
        padding-right: 1vmin;
        padding-left: 2vmin;
        background: #1778e0;
        display: flex;
        align-items: center;
        border-radius: 1vmin;
        color: #fff;
        font-size: 2vmin;
      }
    }

    .footer {
      color: #fff;
      font-size: 14px;
      font-weight: bolder;
      display: flex;
      justify-content: center;

      .footer_lxfs {
        display: inline-block;
        text-align: left;
        min-width: 20em;

        .footer_dh {
          font-size: 20px;
          margin: 10px 0;
        }

        .footer_ly {
          background: #1778e0;
          padding: 5px;
          border-radius: 5px;
          font-weight: normal;
        }

        p {
          font-weight: normal;
          font-size: 10px;
          margin-top: 1em;
          color: #adaaaa;
        }
      }

      .footer_erwm {
        display: flex;

        .img {
          font-size: 12px;
          font-weight: normal;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          align-items: center;
          color: #999;

          img {
            width: 5em;
            margin: 1em;
            background: #fff;
          }
        }
      }
    }

    .footer_gsba {
      border-color: #f8f3f3;
      font-size: 10px;
      color: #999;
      margin-top: 2em;
    }
    .ant-layout-footer {
      padding: 4vmin 1vmin;
    }
  }
}
