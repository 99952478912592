.newsdetails_main {
  width: 100%;
  padding-top: 4vmin;
  .newsdetail_box {
    width: 80%;
    margin: 0 auto;
    .pre_aft {
      background-color: rgb(255, 255, 255);
      border-radius: 1vmin;
      display: flex;
      justify-content: space-between;
      height: 5vmin;
      align-items: center;
      padding: 0 2vmin;
      margin-bottom: 2vmin;
      img {
        width: 2.5vmin;
        height: 2.5vmin;
      }

      .pre span,
      .aft span {
        font-size: 2vmin;
        margin: 0 0.6vmin;
      }
      .pre,
      .aft {
        width: 50%;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-right: 1vmin;
        color: rgb(92, 91, 90);
      }
      .aft {
        float: right;
      }
      .prespan,
      .aftspan {
        width: 7vmin;
        white-space: nowrap;
      }
      .prespan::after {
        content: "|";
        margin-left: 1.2vmin;
      }
      .aftspan::before {
        content: "|";
        margin-right: 1.2vmin;
      }
      .title {
        width: 60vmin;
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .pre img {
        margin-right: 1vmin;
      }
      .aft img {
        margin-left: 1vmin;
      }
      .pre:hover,
      .aft:hover {
        color: rgb(13, 112, 250);
      }
    }
  }
}
