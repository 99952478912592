* {
  margin: 0;
  padding: 0;
  font-size: 16px;
}

#root {
  height: 100%;
}

body {
  height: 100%;
  background: #fff;
}

img {
  border: 0;
  /* 移除图片边框 */
  margin: 0;
  /* 移除图片周围的默认间距 */
  padding: 0;
  /* 移除内边距 */
  display: inline-block;
  /* 设置为行内块元素 */
  max-width: 100%;
  /* 图片最大宽度为父元素宽度 */
  height: auto;
  /* 让图片保持原始纵横比进行缩放 */
}

li {
  list-style: none;
  /* 移除默认的列表标记 */
  margin: 0;
  /* 移除列表项之间的默认间距 */
  padding: 0;
  /* 移除列表项的默认内边距 */
}

// 悬停
.float_btn {

  .ant-tooltip .ant-tooltip-inner {
    background: #fff !important;
  }

  .ant-tooltip {
    --antd-arrow-background-color: #fff;
  }

  .xtys_bt {
    color: #000000;
  }

  .ant-float-btn-group .ant-float-btn {
    // width: 6vmin;
    // height: 6vmin;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
/* 电脑端样式 */
@media screen and (min-width: 1024px) {
  * {
    font-size: 16px;
  }
}
/* 手机端样式 */
@media screen and (max-width: 1023px) {
  * {
    font-size: 3vmin;
  }
}