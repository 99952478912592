.container{
    height: 100%;
    width: 100%;
}
// 自定义覆盖物的 CSS 样式
.custom-marker {
    width: 40px;
    height: 40px;
    background: url('../../assets/imgs/map_xiaoreng.gif') center center no-repeat;
    background-size: 100%;
}