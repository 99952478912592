
/* 电脑端样式 */
@media screen and (min-width: 1024px) {
    .Secrecy_main {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    
        h1 {
            font-size: 3vmin
        }
    
        .daoh {
            width: 100%;
            height: 3.5rem;
            border-bottom: 1px solid #ccc;
            display: flex;
            flex-direction: column;
            align-items: center;
    
            .daoh_content {
                width: 80%;
                height: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
    
                .gsjs {
                    font-weight: bold;
                }
                .mbx {
                    display: flex;
                    color: #928989;
                    align-items: center;
                    img{
    
                        width: 1.2rem;
                        height: 1.2rem;
                        margin: 0 0.7rem;
                        // position: relative;
                        // top: 0.2rem;
                        }
                }
            }
        }
        .Secrecy_big {
            width: 100%;
            background: url('../../assets/imgs/relation/relation_backgrout1.png');
            display: flex;
            justify-content: center;
            .biaodan_main {
                // border: 1px solid red;
                width: 80%;
                margin: 3vmin 0;
                background-color: #fff;
                padding: 8vmin;
            }
        }
    }
}
/* 手机端样式 */
@media screen and (max-width: 1023px) {
    .Secrecy_main {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    
        h1 {
            font-size: 3vmin
        }
    
        .daoh {
            width: 100%;
            height: 13vmin;
            border-bottom: 1px solid #ccc;
            display: flex;
            flex-direction: column;
            align-items: center;
    
            .daoh_content {
                width: 80%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
    
                .gsjs {
                    font-weight: bold;
                }
                .mbx {
                    display: flex;
                    color: #928989;
                    align-items: center;
                    img{
    
                        width: 1.2rem;
                        height: 1.2rem;
                        }
                }
            }
        }
        .Secrecy_big {
            width: 100%;
            background: url('../../assets/imgs/relation/relation_backgrout1.png');
            display: flex;
            justify-content: center;
            .biaodan_main {
                // border: 1px solid red;
                width: 80%;
                margin: 3vmin 0;
                background-color: #fff;
                padding: 8vmin;
            }
        }
    }
}
