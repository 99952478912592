.more {
  position: relative;
  height: 10rem;
  width: 15rem;
  margin: 1rem auto 2rem;
  background-size: contain;
  background-image: url("../../assets/imgs/client/bqbg.png");

  p {
    font-size: 1.5rem;
    font-weight: 600;
    margin: 0 auto;
    text-align: center;
    position: absolute;
    top: 4rem;
    left: 4.6rem;
    // top: 4vmin;
    // left: 4.6vmin;
  }
}
.mySwiper {
  padding-bottom: 4rem;
  .swiper {
    padding-bottom: 3rem;
    width: 70%;
    cursor: pointer;
  }

  .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 16rem;
    height: 17rem;
    margin: 0 1.6rem;
    h4 {
      margin-top: 0.6rem;
      margin-bottom: 5rem;
      text-align: center;
    }
  }

  .swiper-slide img {
    display: block;
    width: 100%;
  }
}
