.box {
  width: 80%;
  margin: 0 auto;
  margin-top: 1rem;
  background: linear-gradient(
    45deg,
    #fff,
    #e4ecef,
    #fff,
    #e4ecef,
    #fff,
    #e4ecef,
    #fff,
    #e4ecef,
    #fff
  );
  .article {
    width: 60%;
    margin: 0 auto;
    margin-bottom: 2rem;
    padding-bottom: 2rem;
    // padding: 0 14rem 2rem 14rem;
    .jianjie {
      margin-top: 2rem;
    }
    // 二级标题的icon
    .icon_t2 {
      width: 1.7rem;
      height: 1.7rem;
      object-fit: cover; /* 图片按比例缩放，保持宽高比并填充整个容器 */
      margin-right: 1.5rem;
    }
    .t2 {
      display: flex;
      align-items: center;
      margin: 3rem 0 1.5rem 0;
    }
    ul {
      margin-bottom: 1.5rem;
    }
    .imgCont {
      object-position: center;
    }
    // 标题
    .title {
      h1 {
        font-size: 1.5rem;
        text-align: center;
        padding: 2rem;
        position: relative;
      }
      .time {
        position: absolute;
        left: 60%;
      }
    }
    p {
      text-indent: 2rem;
      font-size: 1rem;
      line-height: 1.8rem;
      margin-bottom: 1.5rem;
    }
    li {
      margin-left: 1rem;
      margin-bottom: 0.5rem;
      font-size: 1rem;
      list-style: disc;
    }
    li::marker {
      color: rgb(74, 142, 232);
    }
  }
}
