/* 电脑端样式 */
@media screen and (min-width: 1024px) {
    .products_tow {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;

        .content {
            width: 80%;
            // position: relative;

            .mbx {

                img {

                    width: 1.2rem;
                    height: 1.2rem;
                    margin: 0 0.7rem;
                    position: relative;
                    top: 0.2rem;
                }
            }
            .ant-tabs-extra-content{
                margin: 1rem;
            }
            .mian {
                width: 100%;


                .img_box {
                    display: flex;
                    justify-content: center;
                    width: 100%;

                    .title_ms {
                        width: 50%;
                        margin-left: 10vmin;

                        .title {
                            border-bottom: 1px solid #ccc;
                            line-height: 10vmin;
                        }

                        .bt {
                            font-size: 14px;
                            font-weight: bold;
                            margin: 5vmin 0;
                        }

                        .span {
                            margin-bottom: 2vmin;
                        }

                        .title_bott {
                            padding: 5vmin 0;
                            border-top: 1px solid #ccc;
                            display: flex;

                            button {
                                width: 11vmin;
                                height: 5vmin;
                                color: #fff;
                                background-color: #2a7bdd;
                                border: 0;
                                border-radius: 1vmin;
                                margin-right: 3vmin;

                            }

                            .lx {
                                display: flex;
                                flex-direction: column;

                                .ys {
                                    font-weight: bolder;
                                    color: #2a7bdd;
                                    font-size: large;
                                }
                            }
                        }
                    }

                    .img {
                        width: 44%;
                    }
                }

                .content_tow {
                    // display: flex;
                    // justify-content: space-between;
                    width: 100%;
                    margin-top: 3vh;

                    .ico {
                        height: 18px;
                        width: 25px;
                        background: url('../../../../assets/imgs/first_imgs/ico.png') no-repeat center;
                        background-size: 100%;
                        display: inline-block;
                        position: relative;
                        bottom: -2px;
                    }

                    .bt {
                        font-size: 14px;
                        font-weight: bold;
                        // margin: 5vmin 0;
                    }

                }
            }
        }
    }
}

/* 手机端样式 */
@media screen and (max-width: 1023px) {
    .products_tow {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        .content {
            width: 80%;
            position: relative;
            // .ant-tabs-nav .ant-tabs-nav-wrap{
            //     visibility: visible !important;
            //     animation: fadleft 2s !important;
            //     animation-delay: 2s;
            // }

            .mbx {
                display: flex;
                color: #928989;
                align-items: center;

                img {

                    width: 1.2rem;
                    height: 1.2rem;
                    // margin: 0 0.7rem;
                }
            }

            .mian {
                width: 100%;


                .img_box {
                    display: flex;
                    width: 100%;
                    flex-direction: column;

                    .title_ms {
                        width: 100%;
                        // margin-left: 10vmin;

                        .title {
                            border-bottom: 1px solid #ccc;
                            line-height: 10vmin;
                        }

                        .bt {
                            font-size: 14px;
                            font-weight: bold;
                            margin: 5vmin 0;
                        }

                        .span {
                            margin-bottom: 2vmin;
                        }

                        .title_bott {
                            padding: 5vmin 0;
                            border-top: 1px solid #ccc;
                            display: flex;
                            align-items: center;

                            button {
                                width: 16vmin;
                                height: 7vmin;
                                color: #fff;
                                background-color: #2a7bdd;
                                border: 0;
                                border-radius: 1vmin;
                                margin-right: 3vmin;

                            }

                            .lx {
                                display: flex;
                                flex-direction: column;

                                .ys {
                                    font-weight: bolder;
                                    color: #2a7bdd;
                                    font-size: large;
                                }
                            }
                        }
                    }

                    .img {
                        width: 100%;
                    }
                }

                .content_tow {
                    // display: flex;
                    // justify-content: space-between;
                    width: 100%;
                    margin-top: 3vh;

                    .ico {
                        height: 18px;
                        width: 25px;
                        background: url('../../../../assets/imgs/first_imgs/ico.png') no-repeat center;
                        background-size: 100%;
                        display: inline-block;
                        position: relative;
                        bottom: -2px;
                    }

                    .bt {
                        font-size: 14px;
                        font-weight: bold;
                        // margin: 5vmin 0;
                    }

                }
            }
        }
    }
}