.solution_main {
  width: 100%;
  caret-color: transparent;
}
.w95 {
  width: 80%;
  margin: 0 auto;
}
.solution_main {
  // middle
  .middleBox {
    // height: 4rem;
    margin: 0 auto;
    overflow: hidden;
    border-bottom: 1px solid #ccced1;
    background-color: #f6f9f9;

    .middle {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    // left
    .left {
      position: relative;
      top: 1vmin;
      width: 36%;
    }

    // position
    .position {
      height: 2.5rem;
      // width: 50%;

      width: 18rem;
      font-size: 1rem;
      text-align: right;
      line-height: 2.5rem;
      img {
        width: 1.2rem;
        height: 1.2rem;
        margin: 0 0.7rem;
        position: relative;
        top: 0.2rem;
      }
      a {
        color: black;
      }
      span {
        color: rgb(107, 103, 103);
      }
    }
  }

  // 子项按钮
  .settle_btn_Box {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;
    margin: 2rem 0;
    .settle_btn {
      color: #fff;
      border-radius: 0.3rem;
      cursor: pointer;
      margin: 1rem 0;
      transition: all 0.3s ease;
      position: relative;
      display: inline-block;
      box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5),
        7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
      background: linear-gradient(
        0deg,
        rgba(0, 172, 238, 1) 0%,
        rgba(2, 126, 251, 1) 100%
      );
      min-width: 12rem;
      height: 2.5rem;
      line-height: 2.5rem;
      border: none;
      span {
        position: relative;
        display: block;
        padding: 0 0.2rem;
        height: 100%;
        font-size: 1rem;
      }
    }
    .settle_btn:before,
    .settle_btn:after {
      position: absolute;
      content: "";
      right: 0;
      top: 0;
      background: rgba(2, 126, 251, 1);
      transition: all 0.3s ease;
      box-shadow: -1px -1px 20px 0px rgba(255, 255, 255, 1),
        -4px -4px 5px 0px rgba(255, 255, 255, 1),
        7px 7px 20px 0px rgba(0, 0, 0, 0.4), 4px 4px 5px 0px rgba(0, 0, 0, 0.3);
    }
    .settle_btn:before {
      height: 0%;
      width: 0.16rem;
    }
    .settle_btn:after {
      width: 0%;
      height: 0.16rem;
    }
    .settle_btn:hover {
      background: #f0f1f3;
    }
    .settle_btn:hover:before {
      height: 100%;
    }
    .settle_btn:hover:after {
      width: 100%;
    }
    .settle_btn span:hover {
      color: rgba(2, 126, 251, 1);
    }
    .settle_btn span:before,
    .settle_btn span:after {
      position: absolute;
      content: "";
      left: 0;
      bottom: 0;
      background: rgba(2, 126, 251, 1);
      transition: all 0.3s ease;
    }
    .settle_btn span:before {
      width: 0.16rem;
      height: 0%;
    }
    .settle_btn span:after {
      width: 0%;
      height: 0.16rem;
    }
    .settle_btn span:hover:before {
      height: 100%;
    }
    .settle_btn span:hover:after {
      width: 100%;
    }
  }
  // 点击时类名
  .settle_btn_Box {
    .active {
      border-radius: 0.3rem;
      cursor: pointer;
      margin: 1rem 0;
      transition: all 0.3s ease;
      position: relative;
      display: inline-block;
      box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5),
        7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1);

      min-width: 12rem;
      height: 2.5rem;
      line-height: 2.5rem;
      border: none;
      background: #f0f1f3;
      span {
        position: relative;
        display: block;
        padding: 0 0.2rem;
        height: 100%;
        font-size: 1rem;
        color: rgba(2, 126, 251, 1);
      }
    }
    .active:before,
    .active:after {
      position: absolute;
      content: "";
      right: 0;
      top: 0;
      background: rgba(2, 126, 251, 1);
      transition: all 0.3s ease;
      box-shadow: -1px -1px 20px 0px rgba(255, 255, 255, 1),
        -4px -4px 5px 0px rgba(255, 255, 255, 1),
        7px 7px 20px 0px rgba(0, 0, 0, 0.4), 4px 4px 5px 0px rgba(0, 0, 0, 0.3);
    }
    .active:before {
      width: 0.16rem;
      height: 100%;
    }
    .active:after {
      width: 100%;
      height: 0.16rem;
    }

    .active span:before,
    .active span:after {
      position: absolute;
      content: "";
      left: 0;
      bottom: 0;
      background: rgba(2, 126, 251, 1);
      transition: all 0.3s ease;
    }
    .active span:before {
      width: 0.16rem;
      height: 100%;
    }
    .active span:after {
      height: 0.16rem;
      width: 100%;
    }
  }
  // 定位
  // .container {
  //   margin: 0 auto;
  //   // padding-top: 10px;
  //   position: sticky;
  //   top: 110px;
  //   .location {
  //     display: flex;
  //     flex-direction: column;
  //     padding: 5px;
  //     border: 2px solid #4caf50;
  //     width: 200px;
  //     border-radius: 3px;
  //     box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  //   }
  // }

  // 联系我们
  .solution_footer {
    width: 100%;
    height: 44rem;
    background: #ebecec;
    margin: 0 auto;
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;

    .conten {
      width: 70%;
      background: #fff;
      padding: 2rem;
      box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.2),
        -7px -5px 12px rgba(0, 0, 0, 0.2);
    }
  }
}
