.newsdetails1_main {
  width: 100%;
  background-color: rgb(255, 255, 255);
  border-radius: 1vmin;
  // height: 100vmin;
  margin: 2vmin auto;
  padding-top: 2vmin;
  padding-bottom: 8vmin;
  .newsdetail_box {
    width: 80%;
    margin: 0 auto;
    // background-color: rgb(207, 200, 189);
    b {
      display: block;
      margin-top: 2vmin;
      font-size: 2vmin;
    }
    h1,
    h2 {
      text-align: center;
      margin: 4vmin 0;
    }
    h2 {
      font-size: 2.6vmin;
    }

    p {
      text-align: left;
      text-indent: 3.4vmin;
      line-height: 4vmin;
      margin-top: 2vmin;
    }
    .news1_top {
      h1 {
        font-size: 3.3vmin;
      }
      img {
        width: 60vmin;
        height: 40vmin;
        display: flex;
        justify-content: center;
        margin: 0 auto;
        // margin-bottom: 2vmin;
      }
    }
    .imgBox {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
    }
    .littleImg {
      width: 30vmin;
      height: 20vmin;
      margin-top: 2vmin;
    }
    .middleImg {
      width: 60vmin;
      height: 40vmin;
      margin: 0 auto;
      margin-top: 2vmin;

      display: flex;
      justify-content: center;
    }

    .news1_middle {
      // background-color: aquamarine;
      .imgBox1 {
        img {
          margin-top: 2vmin;
          width: 30vmin;
          height: 20vmin;
        }
      }
    }
    .shuImg {
      width: 40vmin;
      height: 70vmin;
      display: flex;
      justify-content: center;
      margin: 0 auto;
      margin-top: 2vmin;
    }
    // .grid {
    //   display: grid;
    //   /* grid-template-columns: 1fr 1fr 1fr 1fr 1fr; */
    //   grid-template-columns: repeat(5, 1fr);
    //   /* grid-template-rows: 1fr 1fr 1fr; */
    //   grid-template-rows: repeat(3, 1fr);
    //   grid-gap: 1em;
    //   margin: 3em;
    // }
    // .grid > * {
    //   background: rgba(0, 0, 0, 0.6);
    //   color: white;
    //   padding: 2em;
    //   border-radius: 0.5em;
    //   font-size: 2em;
    // }
    // .grid .d {
    //   grid-column: 4 / 6;
    //   grid-row: 1 / 3;
    // }
    // .grid .f {
    //   grid-column: 2 / 4;
    //   grid-row: 2 / 4;
    // }
  }
}
