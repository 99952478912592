// banner
.w95 {
  width: 80%;
  margin: 0 auto;
}
.client_box {
  width: 100%;
  height: 100%;
  background: url("../../assets/imgs/client/bj.jpg") no-repeat center;
  background-size: 100%;
}
// 客户案例
.case {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 5vh;
  flex-direction: column;
  align-items: center;

  .title {
    font-size: 2rem;
    font-weight: bolder;
  }

  .title2 {
    font-weight: bolder;
    margin: 1em;
    .ico {
      height: 1.6rem;
      width: 1.8rem;
      background: url("../../assets/imgs/first_imgs/ico.png") no-repeat center;
      background-size: 100%;
      display: inline-block;
      position: relative;
      bottom: -0.1rem;
    }
  }

  .content {
    display: flex;
    width: 80%;
    justify-content: space-between;
    margin-bottom: 1vmin;
    flex-wrap: wrap;

    .item {
      margin: 0 auto;
      box-shadow: 7px 7px 5px rgba(0, 0, 0, 0.2);
      width: 24rem;
      height: 19rem;
      background-size: cover;
      background-position: center;
      border: 0.17rem solid #dfdada;
      position: relative;
      overflow: hidden;
      margin-bottom: 2rem;
      cursor: pointer;
      transition: all 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55);
      .item_sm {
        height: 3rem;
        width: 100%;
        background: #fff;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
        z-index: 5;
        position: absolute;
        bottom: 0em;
        transition: all 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55);
        text-align: center;
      }

      .item_tb {
        position: absolute;
        left: -3em;
        width: 0%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        transition: all 0.3s cubic-bezier(0.26, -0.06, 0.71, 1.01);
        p {
          color: transparent;
        }
      }
    }

    .item:hover .item_sm {
      color: #fff;
      background: #2a7bdd !important;
    }

    .item:hover .item_tb {
      width: 100%;
      left: 0;
      background: rgba(0, 0, 0, 0.5);
      p {
        color: white;
      }
    }

    .item:hover {
      transform: scale(1.1);
    }
  }
}

.page {
  width: 80%;
  margin: 1vh auto;
  background-color: #2a7bdd;
  .pagination {
    float: right;
    margin-bottom: 1vh;
  }
}
